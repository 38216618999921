import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Form from '../components/form'

const PostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  // const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className="content">
        {post.feature_image ? (
          <figure className="post-feature-image">
            <img src={post.feature_image} alt={post.title} />
          </figure>
        ) : null}
        <section className="post-full-content">
          <h1 className="text-2xl mt-16">{post.frontmatter.title}</h1>

          {/* The main post content */}
          <section
            className="content-body load-external-scripts"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </section>
      </article>
      <Form />
      <Footer message="← Back to blog index" to="/blog/" />
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
