import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

const Form = ({ ...props }) => {
  const { register, handleSubmit, errors } = useForm()
  const [submitted, setSubmitted] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)

  const onSubmit = async data => {
    setSubmitted(true)
    setSubmissionError(false)
    try {
      const response = await fetch(
        `https://app.convertkit.com/forms/1381151/subscriptions`,
        {
          method: 'post',
          body: JSON.stringify(data, null, 2),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )

      const responseJson = await response.json()

      if (responseJson.status === 'failed') {
        setSubmitted(false)
        setSubmissionError(
          'Yikes! Something in the interwebs broke. Please try again.',
        )
      }

      if (responseJson.status === 'quarantined') {
        window.location = responseJson.url
      }
    } catch (error) {
      setSubmitted(false)
      setSubmissionError(
        'Yikes! Something in the interwebs broke. Please try again.',
      )
    }
  }

  return (
    <>
      <h2 className="mt-12">Never miss a post again</h2>
      <p>Get each new blog post delivered directly to your inbox.</p>
      {!submitted && (
        <form onSubmit={handleSubmit(onSubmit)} className="mb-8">
          {submissionError && <p className="text-red-600">{submissionError}</p>}
          <div className="w-64 mb-4">
            <label
              htmlFor="fields[first_name]"
              className="text-gray-500 text-xs uppercase tracking-widest font-extrabold ml-px"
            >
              First name
            </label>
            <input
              id="fields[first_name]"
              name="fields[first_name]"
              className="form-input leading-5 mt-1 block w-full"
              ref={register({ required: true })}
            />
            {errors.first_name && (
              <span className="text-sm text-red-600">
                First name is required
              </span>
            )}
          </div>
          <div className="w-64 mb-4">
            <label
              htmlFor="email_address"
              className="text-gray-500 text-xs uppercase tracking-widest font-extrabold ml-px mt-4"
            >
              Email
            </label>
            <input
              id="email_address"
              type="email_address"
              name="email_address"
              className="form-input leading-5 mt-1 block w-full"
              ref={register({ required: true })}
            />
            {errors.email_address && (
              <span className="text-sm text-red-600">
                Please enter valid email address.
              </span>
            )}
          </div>
          <button
            type="submit"
            className="bg-blue-500 border border-transparent text-white rounded leading-5 mt-2 font-medium px-3 py-2 hover:bg-blue-600"
          >
            Sign me up!
          </button>
        </form>
      )}
      {submitted && (
        <p>
          Check your inbox! We've sent you a confirmation email. Please confirm
          your email.
        </p>
      )}
    </>
  )
}

export default Form
